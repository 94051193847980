// Custom theme

@import 'variables';

@import '~bootstrap/scss/bootstrap.scss';

.background {
  background-color: black;
}

.body-container {
  background-color: $grey !important;
  height: 100vh;
  max-width: 576px !important;
  margin: auto;

  // @media (min-width: 576px) {
  //   width: 70% !important;
  //   margin: auto;
  // }

  // // Medium devices (tablets, 768px and up)
  // @media (min-width: 768px) {
  //   width: 70% !important;
  //   margin: auto;
  // }

  // // Large devices (desktops, 992px and up)
  // @media (min-width: 992px) {
  //   width: 60% !important;
  //   margin: auto;
  // }
}

// .favorites-container {
//   min-height: 100vh;
//   background-color: $grey;
// }

.search-container {
  position: relative;
  overflow: hidden;
  background-color: $black;

  .kierrepulla,
  .smoothie,
  .croissant {
    position: absolute;
  }

  .kierrepulla {
    top: -4rem;
    width: 7rem;
    left: -2rem;
  }

  .smoothie {
    top: 0;
    right: -1rem;
    width: 6rem;
    transform: rotate(-20deg);
    z-index: 0;
  }

  .croissant {
    bottom: -5rem;
    left: 8%;
    width: 6rem;
    transform: rotate(190deg);
  }

  @media (min-width: 992px) {
    .kierrepulla,
    .smoothie,
    .croissant {
      width: 7rem;
    }
  }
}

// .search {
//   border-radius: 4rem !important;
//   border: 2px solid $mint;
//   height: 3rem;
//   font-size: 1rem;
//   padding-left: 1.5rem;
// }

.main-title {
  font-size: 2rem;
  text-align: center;
  justify-self: center;
  color: $white;
  z-index: 10;
}

// .search-results {
//   margin-top: 0.5rem;
//   background-color: $white;
//   border-radius: 0.9rem;
//   padding: 0.2rem 1.5rem;
//   border: 2px solid $mint;
//   z-index: 10;

//   > .list-group-item,
//   .search-result-item,
//   .list-group-item:first-child,
//   .list-group-item:last-child {
//     border-bottom: 1px solid $mint;
//     background-color: transparent;
//     color: $grey;
//     padding: 0.3rem 0.4rem;
//     margin-bottom: 0.3rem;
//   }

//   > .list-group-item:last-child {
//     border-bottom: none;
//     margin-bottom: 0;
//   }
// }

// .list-group-item:last-child {
//   margin-bottom: 0;
// }

// .nav-tabs {
//   border-top: 2px solid $white;
// }

// .nav-item,
// .nav-fill {
//   background-color: $mint !important;
// }

// .nav-link {
//   color: $grey;
//   background-color: $white;

//   @include text-bold-caps;
// }

// .nav-link.active {
//   color: $grey !important;
//   background-color: $mint !important;
//   @include text-bold-caps;
// }

/// CARD-STYLES

// .list-group-item {
//   border: none;
//   padding: 0 0 0.45em 0;
// }

.sales-channel-title {
  font-weight: 900;
  margin-bottom: 0.2rem;
}

.card {
  background-color: #fff;
}

.card-body {
  padding: 0.8rem;
  padding-left: 1.5rem;
}

.card-title {
  margin: 0;
  font-size: 1rem;
}

.card-img-container {
  position: relative;
  overflow: hidden;
}

.card-img-top {
  box-shadow: 3px 3px 5px $grey-20;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-img-title-container {
  position: absolute;
  top: 0;
  color: $white;
  width: 100%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > h1 {
    font-size: 2rem;
  }
}

// .card-address {
//   font-size: 0.9rem;
//   padding-bottom: 1.1rem;

//   .card-city {
//     @include text-bold-caps;
//   }
// }

// .card-info-row {
//   display: flex;
//   flex-direction: row;
//   margin-bottom: 0rem;

//   > div > p {
//     margin-bottom: 0;
//   }

//   > div > p > span {
//     @include text-bold-caps;
//   }
// }

// .card-times {
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 0;
//   margin-left: 0.1rem;
// }

// .card-icon {
//   margin-right: 0.2rem;
//   margin-top: 0.2rem;
// }

// Custom button variants

.btn-primary {
  background-color: $mint;
  color: $black;
  border-color: $mint;
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 25px;
  @include text-bold-caps;
  letter-spacing: 0.1px;

  .btn-primary:active {
    background-color: $grey;
    color: $white;
    border-color: $grey;
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:active,
.btn-primary:hover {
  background-color: $grey;
  color: $white;
  border-color: $grey;
  border-color: transparent;
}

// .btn.btn-search {
//   position: absolute;
//   right: -1px;
//   top: 3px;
//   z-index: 11;
//   border-radius: 50% !important;
// }

// .btn.btn-search:focus {
//   box-shadow: none;
//   z-index: 11;
// }

// .btn-favourite,
// .btn-favourite-active,
// .btn-favourite:hover,
// .btn-favourite-active:hover {
//   border-color: transparent;
//   padding: 0;
//   margin: 0;
//   width: fit-content;
//   height: auto;
//   position: absolute;
//   right: 2rem;
//   font-size: 1.8rem;
//   z-index: 10;
//   transform: translateY(-0.2rem);
//   background-color: white;

//   > svg {
//     vertical-align: top;
//   }

//   @media (max-width: 576px) {
//     font-size: 1.8rem;
//     z-index: 0;
//     right: 1rem;
//   }
// }

// .btn-favourite {
//   color: $mint;
// }

// .btn-favourite-active {
//   color: $grey-20 !important;
// }

// .btn-hidden {
//   display: none;
// }

// .no-favorites {
//   color: $white;
//   text-align: center;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   padding-top: 3rem;
//   font-size: 0.9rem;
// }
