//COLORS
$blue: #005496;
$blue-70: rgba(0, 84, 150, 0.7);
$white: hsl(0, 0%, 100%);
$white-20: rgba(255, 255, 255, 0.2);
$white-60: rgba(255, 255, 255, 0.6);
$white-70: rgba(255, 255, 255, 0.7);
$white-90: rgba(255, 255, 255, 0.9);
$grey: #343434;
$grey-20: rgba(52, 52, 52, 0.2);
$grey-60: rgba(52, 52, 52, 0.6);
$grey-80: rgba(52, 52, 52, 0.8);
$mint: #78cdd1;
$bodytext: #000000de;
$beige: #ddd3af;

$btn-focus-box-shadow: none;

$enable-shadows: true;
$body-color: #000000de;
$border-color: $white;

$nav-tabs-border-radius: 0rem;

/// FONTS

$bold: 600;

/// MIXINS

@mixin text-bold-caps {
  font-weight: $bold;
  text-transform: uppercase;
  font-size: 0.9rem;
}
